<template>
  <div id="text-field" autocomplete="off">
    <label :for="id">{{ label }}</label>
    <input
      :class="[textFieldError ? 'input-error' : '']"
      autocomplete="off"
      :type="type"
      :max="type === 'date' ? maxAgeValidate : null"
      @input="$emit('input', $event.target.value)"
      v-bind="{ placeholder, id, disabled, value }"
      v-on:keyup.enter="onEnter"
    />
    <span>{{ textFieldError && textFieldError }}</span>
    <small class="hint" v-if="required">*Campo obrigatório</small>

    <div><slot></slot></div>
  </div>
</template>
<script>
const TYPES = [
  "text",
  "password",
  "new-password",
  "current-password",
  "e-mail",
  "number",
  "url",
  "tel",
  "search",
  "color",
  "date",
];
const includes = (types) => (type) => types.includes(type);

export default {
  name: "TextField",

  props: {
    label: String,
    value: {
      type: [String, Number, Date],
    },
    type: {
      type: String,
      default: "text",
      validator(value) {
        const isValid = includes(TYPES)(value);
        if (!isValid) {
          console.warn(`allowed types are ${TYPES}`);
        }
        return isValid;
      },
    },
    placeholder: [String, Number],
    id: {
      type: String,
      required: true,
    },
    disabled: String,
    required: Boolean,
    textFieldError: String,
  },
  computed: {
    maxAgeValidate() {
      const date18YrsAgo = new Date();
      date18YrsAgo.setFullYear(date18YrsAgo.getFullYear() - 18);
      return date18YrsAgo.toISOString().substr(0, 10);
    },
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
    onEnter() {
      this.$emit("onEnter");
    },
  },
};
</script>
<style>
#text-field {
  margin-bottom: 8px;
}
#text-field span {
  -webkit-font-smoothing: antialiased;
  font-weight: normal;
  padding: 0;
  border: 0;
  background: transparent;
  vertical-align: baseline;
  color: #ff5555;
  font-size: 13px;
  line-height: 1.5;
  margin: 5px 0px;
  list-style: disc;
  margin-top: 0;
}

#text-field .input-error {
  border: red 3px solid;
  border-radius: 8px;
  height: 45px;
  margin-bottom: 0px;
}
</style>
