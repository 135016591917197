<template>
  <button :class="btnClass" :disabled="disabled || loading" v-on="$listeners">
    <div class="row space-between align-center">
      <span>{{ text }}</span>
      <div v-if="loading" class="btn-load-container btn-load">
        <div class="loader"></div>
      </div>
      <div v-else-if="!loading && icon">
        <slot name="icon"></slot>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "custom-button",
  props: {
    text: {
      type: [String, Number],
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    btnClass() {
      const btnclass = this.outlined
        ? `btn-outlined-${this.color}`
        : `btn-${this.color}`;
      return `${btnclass}`;
    },
  },
};
</script>

<style>
button {
  box-sizing: border-box;
  padding: 10px 16px;
  transition: color 200ms ease;
  border-radius: 8px;
  outline: none;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  display: block;
  width: 100%;
  margin-bottom: 18px;
  font-family: "Inter", sans-serif;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}
.btn-load-container {
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  box-sizing: border-box;
}

.load-container:before {
  font-size: 14px;
  line-height: 200%;
  text-align: center;
  color: #7d7f80;
  box-sizing: border-box;
}

.btn-load .loader {
  border-radius: 50%;
  width: 0.8em;
  height: 0.8em;
  /* text-indent: -9999em; */
  border: 0.35em solid #e3e3e3;
  border-left: 0.35em solid #303233;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  -moz-transform: translateZ(0);
  animation: load 0.4s infinite linear;
  -o-animation: load 0.4s infinite linear;
  -webkit-animation: load 0.4s infinite linear;
  -moz-animation: load 0.4s infinite linear;
}

@-ms-keyframes load {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-moz-keyframes load {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes load {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes load {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
  }
}
</style>
