<template>
  <div>
    <div class="container-wrapper">
      <div class="d-flex space-between">
        <div style="width: 100%; margin-right: 50px">
          <strong class="title">Alterar email</strong>

          <text-field
            id="email"
            v-model="user.email"
            :value="user.email"
            label="E-mail atual"
            placeholder="seu e-mail atual"
            type="text"
          />

          <p style="padding: 10px 0">
            A sua conta do PayPal precisa ter o <b>mesmo e-mail</b> da sua conta
            na Inside Brasil. Caso os e-mails sejam diferentes, você não
            conseguirá receber os pagamentos. Confira se o seu e-mail cadastro
            na Inside Brasil é o mesmo cadastrado no PayPal.
          </p>

          <text-field
            id="email"
            v-model="user.newEmail"
            :value="user.newEmail"
            label="Novo E-mail"
            placeholder="digite um novo e-mail"
            type="text"
          />

          <text-field
            id="password"
            v-model="user.password"
            :value="user.password"
            label="Senha"
            placeholder="digite sua senha"
            type="password"
          />

          <custom-button
            :loading="isLoading"
            class="mt-10 button"
            icon
            text="Confirmar"
            @click="saveNewEmail()"
          >
            Confirmar
            <div slot="icon">
              <span class="material-icons material-icons-outlined">
                arrow_forward
              </span>
            </div>
          </custom-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/api/services/user.js";
import TextField from "@/components/Form/TextField/index.vue";
import CustomButton from "@/components/Button/index.vue";

export default {
  components: {
    TextField,
    CustomButton,
  },

  data() {
    return {
      isLoading: false,
      user: {},
    };
  },

  methods: {
    async saveNewEmail() {
      this.isLoading = !this.isLoading;

      try {
        await UserService.saveNewEmail(this.user);

        this.$toast.success("E-mail alterado!");
      } catch (err) {
        this.$toast.error(err.response.data.message ?? "Erro interno");
      } finally {
        this.isLoading = !this.isLoading;
      }
    },
  },
};
</script>

<style scoped>
.container-wrapper {
  background-color: #fafafa;
  border-radius: 4px;
  margin: 1em;
  padding: 2em;
}

.content-wrapper {
  min-width: 200px;
  max-width: 600px;
}

.title {
  font-size: 1rem;
  line-height: 1;
  color: var(--gray-dark);
}

.subtitle {
  font-size: 0.75rem;
  line-height: 1.3;
  color: var(--gray);
}

.flex-col {
  flex-direction: column;
}

.gap-small {
  gap: 0.5em;
}

.gap-medium {
  gap: 1em;
}

.text-group {
  max-width: 400px;
}

.fitted-btn {
  width: fit-content;
  white-space: nowrap;
}

.confirmation-modal ::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-modal ::v-deep .modal-content {
  max-width: 400px;
  padding: 2em;
}

.confirmation-modal ::v-deep .modal__title {
  margin: 0;
  justify-content: flex-start;
}

.confirmation-modal ::v-deep .modal__content {
  margin: 0;
  padding: 1em 0;
  text-align: left;
}

.confirmation-modal ::v-deep .modal__action {
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .confirmation-modal ::v-deep .modal-content {
    width: 100%;
    margin: 20px;
  }

  .confirmation-modal ::v-deep .modal__title {
    justify-content: center;
  }

  .confirmation-modal ::v-deep .modal__content {
    text-align: center;
  }

  .modal-actions {
    flex-flow: column-reverse;
    gap: 0;
  }

  .fitted-btn {
    width: 100%;
    white-space: nowrap;
  }
}
</style>
